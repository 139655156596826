import React, { Component } from 'react';
import './App.scss';
import 'typeface-roboto';

//material
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const theme = createMuiTheme({
    palette: {
        primary: {
          main: '#E5322D',
        },
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 16,
    },
});
theme.typography.h1={
  fontSize: 30,
  marginTop:30,
  marginBottom:30,
};
theme.typography.h2={
  fontSize: 28,
  marginTop:15,
  marginBottom:10,
};
theme.typography.h3={
  fontSize: 24,
  marginTop:15,
  marginBottom:5,
};
theme.typography.body1={
  marginTop:10,
  marginBottom:5,
};
theme.typography.body2={
  marginTop:10,
  marginBottom:5,
  color:'#666',
};

class App extends Component {
    render(){
        return <ThemeProvider theme={theme}>
        <Container maxWidth='md'>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Grid container justify='center'>
                <Grid item xs={7}>
                    <img alt="passerelle" style={{width:'100%'}} src='/assets/passerelle.svg'/>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Grid container justify='center'>
                <Grid item xs={7}>
                  <Typography variant="h1" style={{textAlign:'center'}}>
                  SITE en cours de RECONSTRUCTION
                  </Typography>
                </Grid>
            </Grid>
        </Container>
      </ThemeProvider>;
    }
}

export default App;
